


































import { Component, Vue } from 'vue-property-decorator';
import AssignmentList from '../../components/assignments/assignment-list/AssignmentList.vue';
import AssignmentFilter from '@/components/assignments/assignment-list/AssignmentFilter.vue';
import { userModule } from '@/store/modules/user/user.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';

@Component({
  components: {
    AssignmentFilter,
    AssignmentList,
  },
})
export default class AssignmentOverview extends Vue {
  public get showCreateAssignmentBtn(): boolean {
    return userModule.role === AppRole.Intern;
  }
}
