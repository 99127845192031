



























import { Component, Emit } from 'vue-property-decorator';
import { InputBase } from '@itaa/ui-components';
import { IActivitySelection } from './activity-selection';
import DisciplineWithActivitiesList from './DisciplineWithActivitiesList.vue';

@Component({
  components: {
    InputBase,
    DisciplineWithActivitiesList,
  },
})
export default class ActivitySelectionInput extends InputBase<IActivitySelection> {
  public selected: string | null = null;

  public mounted(): void {
    if (this.value) {
      this.selected = 'filled';
    }
  }

  @Emit('input')
  onValueChanged(value: IActivitySelection): IActivitySelection {
    this.selected = value ? 'filled' : null;
    return value;
  }
}
