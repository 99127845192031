import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column h-100"},[_c('itaa-text-input',{staticClass:"mt-3",attrs:{"label":_vm.$t('actions.search'),"required":false},model:{value:(_vm.activityFilter),callback:function ($$v) {_vm.activityFilter=$$v},expression:"activityFilter"}}),_c(VList,{staticClass:"mt-n8 flex-grow-1 overflow-y-auto",attrs:{"color":"transparent"}},_vm._l((_vm.disciplines),function(discipline){return _c(VTooltip,{key:discipline.id,attrs:{"disabled":_vm.isDisciplineEnabled(discipline),"max-width":"600","open-delay":"750","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListGroup,_vm._g(_vm._b({attrs:{"append-icon":null,"prepend-icon":"$caret-next","disabled":!_vm.isDisciplineEnabled(discipline),"ripple":_vm.isDisciplineEnabled(discipline)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,{class:_vm.listItemContentClass(discipline)},[_c(VListItemTitle,[_vm._v(" "+_vm._s(discipline.label)+" ")])],1)]},proxy:true}],null,true)},'v-list-group',attrs,false),on),[_c(VListItemGroup,{attrs:{"multiple":"","value":_vm.activities},on:{"change":function (a) { return _vm.onActivitiesSelected(a, discipline.id); }}},_vm._l((discipline.activities),function(activity){return _c(VListItem,{key:activity.id,attrs:{"value":activity.id,"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(activity.label)+" ")])],1)]}}],null,true)})}),1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('discipline.activitySelectionTooltip'))+" ")])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }