































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IApiBaseDiscipline } from '@/store/modules/masterdata/contracts/discipline-api.contract';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';

@Component({})
export default class DisciplineList extends Vue {
  @Prop()
  public disciplineSelection!: string[];

  public disciplineFilter = '';

  public updateDisciplineFilter(val: string): void {
    this.disciplineFilter = val;
  }

  @Emit('discipline-selected')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public disciplineSelected(val: string): void {
    return;
  }

  public get disciplines(): IApiBaseDiscipline[] {
    const disciplines = masterdataModule.disciplines.map((discipline) => ({
      id: discipline.id,
      label: discipline.label,
    }));
    if (this.disciplineFilter) {
      return disciplines.filter((discipline) => {
        return discipline.label.toLowerCase().includes(this.disciplineFilter.toLowerCase());
      });
    }
    return disciplines;
  }
}
