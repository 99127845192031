




























































import { Component, Vue } from 'vue-property-decorator';
import DisciplineSelectionInput from '@/components/form-components/discipline-selection-input/DisciplineSelectionInput.vue';
import { IAssignmentFilterModel } from '@/store/modules/assignments/contracts/assignment.api-contract';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';

@Component({
  components: { DisciplineSelectionInput },
})
export default class AssignmentFilter extends Vue {
  public storedFilterJSON = '';
  public loading = false;

  public filterModel!: IAssignmentFilterModel;

  mounted(): void {
    this.filterModel = this.storeFilterModel;
  }

  public get storeFilterModel(): IAssignmentFilterModel {
    return assignmentModule.assignmentFilterModel;
  }

  public filterModelUpdated(): void {
    assignmentModule.updateFilterModel(this.filterModel);
  }

  public startDateSelected(date: string): void {
    this.filterModel.startDate = date;
    this.filterModelUpdated();
  }

  public endDateSelected(date: string): void {
    this.filterModel.endDate = date;
    this.filterModelUpdated();
  }

  public clearFilterModel(): void {
    assignmentModule.resetFilterModel();
    this.storedFilterJSON = '';
    this.filterModel = this.storeFilterModel;
    //this.filterModel.hasComments = this.storeFilterModel.hasComments;
  }

  public async storeAndEmitFilter(): Promise<void> {
    this.storedFilterJSON = JSON.stringify(this.filterModel);
    this.loading = true;
    try {
      await assignmentModule.fetchAssignments({ clearAssignments: true });
    } finally {
      this.loading = false;
    }
  }

  public get filterChanged(): boolean {
    return this.storedFilterJSON !== JSON.stringify(this.filterModel);
  }
}
