

























































import { Component, VModel, Vue } from 'vue-property-decorator';
import { masterdataModule } from '@/store/modules/masterdata/masterdata.module';
import { IApiDiscipline } from '@/store/modules/masterdata/contracts/discipline-api.contract';
import { IActivitySelection } from './activity-selection';

@Component({})
export default class DisciplineWithActivitiesList extends Vue {
  @VModel()
  public activitySelection!: IActivitySelection | null;

  public activityFilter = '';

  public get activities(): string[] {
    return this.activitySelection?.activities || [];
  }

  public get disciplines(): IApiDiscipline[] {
    const disciplines = masterdataModule.disciplines;
    if (this.activityFilter) {
      return disciplines
        .map((discipline) => ({
          id: discipline.id,
          label: discipline.label,
          activities: discipline.activities.filter((activity) => {
            return activity.label.toLowerCase().includes(this.activityFilter.toLowerCase());
          }),
        }))
        .filter((discipline) => discipline.activities.length);
    }
    return disciplines;
  }

  public onActivitiesSelected(activities: string[], discipline: string): void {
    this.activitySelection = activities?.length
      ? {
          activities: activities,
          discipline: discipline,
        }
      : null;
  }

  public isDisciplineEnabled(discipline: IApiDiscipline): boolean {
    return !this.activitySelection?.discipline || discipline.id === this.activitySelection?.discipline;
  }

  public listItemContentClass(discipline: IApiDiscipline): string {
    return !this.isDisciplineEnabled(discipline) ? 'text--disabled' : '';
  }
}
