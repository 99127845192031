




























import { PreviousPageMixin } from '@itaa/ui-components';
import { Component } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import AssignmentForm from '@/components/assignments/assignment-form/AssignmentForm.vue';
import { IApiAssignmentData } from '@/store/modules/assignments/contracts/assignment.api-contract';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { mixins } from 'vue-class-component';
import { IAssignmentDetails } from '@/components/assignments/assignment-form/model';
import { notificationService } from '@/services/notification.service';

@Component({
  components: {
    AssignmentForm,
    ValidationObserver,
  },
})
export default class CreateAssignmentView extends mixins<PreviousPageMixin>(PreviousPageMixin) {
  public assignmentDetails: IAssignmentDetails = {duration: {hours: null, minutes: null}} as IAssignmentDetails;
  public creating = false;

  public async createAssignment(): Promise<void> {
    const mappedData: IApiAssignmentData = {
      assignmentDate: this.assignmentDetails.assignmentDate,
      duration: `${this.assignmentDetails.duration.hours}:${this.assignmentDetails.duration.minutes}`,
      discipline: this.assignmentDetails.activitySelection.discipline,
      activities: this.assignmentDetails.activitySelection.activities,
    };
    if (this.assignmentDetails.comment) {
      mappedData.comments = [this.assignmentDetails.comment];
    }
    try {
      this.creating = true;
      await assignmentModule.createAssignment(mappedData);
      assignmentModule.clearAssignments();
      assignmentModule.clearAssignment();
      notificationService.onSuccess(this.$t('createAssignment.successMessage').toString())
      this.goBack();
    } catch {
      notificationService.showGenericError();
    } finally {
      this.creating = false;
    }
  }

  public goBack(): void {
    this.goToPrevious({
      name: this.Routes.Root,
    });
  }
}
