



















































import { Component, Vue } from 'vue-property-decorator';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { IApiAssignment } from '@/store/modules/assignments/contracts/assignment.api-contract';
import AssignmentComments from './AssignmentComments.vue';
import AssignmentDetails from './AssignmentDetails.vue';
import AssignmentSelectionTable from './AssignmentSelectionTable.vue';
import Choose from '@/assets/svg/choose.svg';
import NoData from '@/assets/svg/no-data.svg';

@Component({
  components: {
    AssignmentComments,
    AssignmentDetails,
    AssignmentSelectionTable,
    Choose,
    NoData,
  },
})
export default class AssignmentList extends Vue {
  public loading = false;

  public async mounted(): Promise<void> {
    this.loading = true;
    await assignmentModule.fetchAssignments();
    this.loading = false;
  }

  public get assignments(): IApiAssignment[] {
    return assignmentModule.assignments.content;
  }

  public get assignmentsLoaded(): boolean {
    return assignmentModule.assignments.loaded;
  }

  public get selectedAssignment(): IApiAssignment | null {
    return assignmentModule.assignment;
  }

  public get nbOfAssignments(): number {
    return assignmentModule.nbOfAssignments;
  }

  public get totalTime(): { hours: number; minutes: number } {
    return assignmentModule.totalTime;
  }
}
