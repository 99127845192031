
















































import { Component, VModel, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { IApiAssignment } from '@/store/modules/assignments/contracts/assignment.api-contract';
import { nameof } from 'ts-simple-nameof';
import dayjs from 'dayjs';
import InfiniteLoading from 'vue-infinite-loading';

@Component({
  components: {
    InfiniteLoading,
  },
})
export default class AssignmentSelectionTable extends Vue {
  @VModel()
  public selectedAssignment!: IApiAssignment | null;

  public get assignments(): IApiAssignment[] {
    return assignmentModule.assignments.content;
  }

  public get allAssignmentsLoaded(): boolean {
    return assignmentModule.allLoaded;
  }

  public getRowClass(assignment: IApiAssignment): string {
    return assignment.id === this.selectedAssignment?.id
      ? 'assignment-selection-table__row assignment-selection-table__row--selected'
      : 'assignment-selection-table__row';
  }


  public onAssignmentSelected(assignment: IApiAssignment): void {
    assignmentModule.fetchAssignment(assignment.id);
  }

  public get headers(): DataTableHeader[] {
    return [
      {
        text: '',
        value: nameof<IApiAssignment>((a) => a.comments),
        width: '4rem',
      },
      {
        text: this.$t('assignment.date').toString(),
        value: nameof<IApiAssignment>((a) => a.assignmentDate),
      },
      {
        text: this.$t('assignment.duration').toString(),
        value: nameof<IApiAssignment>((a) => a.duration),
      },
      {
        text: '',
        value: 'actions',
        width: '4rem',
      },
    ];
  }

  public formatDate(date: string): string {
    return dayjs(date).format('L');
  }

  public formatDuration(duration: string): string {
    const [ hours, minutes ] = duration.split(':');
    const hoursText = hours ? this.$t('time.hourDuration', { hours }) : '';
    const minutesText = minutes ? this.$t('time.minuteDuration', { minutes }) : '';
    return `${hoursText} ${minutesText}`.trim();
  }

  public onLastRowReached([entry]: IntersectionObserverEntry[]): void {
    if (entry.isIntersecting) {
      assignmentModule.fetchMoreAssignments();
    }
  }
}
