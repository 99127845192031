









































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { IAssignmentDetails } from './model';
import ActivitySelectionInput from '@/components/form-components/activity-selection-input/ActivitySelectionInput.vue';
import Adjustment from '@/assets/svg/adjustment.svg';
import TimeInput from '@/components/form-components/TimeInput.vue';

@Component({
  components: {
    ActivitySelectionInput,
    Adjustment,
    TimeInput,
  },
})
export default class AssignmentForm extends Vue {
  @VModel({})
  public assignmentDetails!: IAssignmentDetails;

  @Prop()
  public readonly informationalText!: string;

  @Prop()
  public readonly showCommentsField!: boolean;
}
