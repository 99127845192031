





























import { Component, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { mixins } from 'vue-class-component';
import { PreviousPageMixin } from '@itaa/ui-components';
import { IAssignmentDetails } from '@/components/assignments/assignment-form/model';
import AssignmentForm from '@/components/assignments/assignment-form/AssignmentForm.vue';
import { notificationService } from '@/services/notification.service';

@Component({
  components: {
    AssignmentForm,
    ValidationObserver,
  },
})
export default class EditAssignmentView extends mixins<PreviousPageMixin>(PreviousPageMixin) {
  @Prop()
  public readonly assignmentId!: string;

  public editing = false;

  public assignmentDetails: IAssignmentDetails | null = {} as IAssignmentDetails;

  public async created(): Promise<void> {
    await assignmentModule.fetchAssignment(this.assignmentId);
    const assignment = assignmentModule.assignment;
    if (!assignment) {
      return;
    }
    const durationArray = assignment.duration.split(':');
    this.assignmentDetails = {
      activitySelection: {
        activities: assignment.activities.map((a) => a.id),
        discipline: assignment.discipline.id,
      },
      assignmentDate: assignment.assignmentDate,
      duration: { hours: parseInt(durationArray[0]), minutes: parseInt(durationArray[1]) },
    };
  }

  public async editAssignment(): Promise<void> {
    if (!this.assignmentDetails) {
      return;
    }
    this.editing = true;
    try {
      await assignmentModule.editAssignment({
        assignmentId: this.assignmentId,
        assignmentData: {
          activities: this.assignmentDetails.activitySelection.activities,
          assignmentDate: this.assignmentDetails.assignmentDate,
          discipline: this.assignmentDetails.activitySelection.discipline,
          duration: `${this.assignmentDetails.duration.hours}:${this.assignmentDetails.duration.minutes}`,
        },
      });
      notificationService.onSuccess(this.$t('editAssignment.success').toString());
      this.goBack();
    } catch {
      notificationService.showGenericError();
    } finally {
      this.editing = false;
    }
  }

  public goBack(): void {
    this.goToPrevious({
      name: this.Routes.AssignmentOverview,
    });
  }
}
