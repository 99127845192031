import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"assignment-selection-table",attrs:{"headers":_vm.headers,"items":_vm.assignments,"hide-default-footer":true,"items-per-page":-1,"item-class":_vm.getRowClass,"disable-sort":true,"height":"100%","fixed-header":""},on:{"click:row":_vm.onAssignmentSelected},scopedSlots:_vm._u([{key:"item.comments",fn:function(ref){
var item = ref.item;
return [(item.comments.length)?_c(VIcon,[_vm._v(" $conversation ")]):_vm._e()]}},{key:"item.assignmentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.assignmentDate ))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDuration(item.duration ))+" ")]}},{key:"item.actions",fn:function(){return [_c(VIcon,[_vm._v(" $caret-next ")])]},proxy:true},{key:"foot",fn:function(){return [(!_vm.allAssignmentsLoaded)?_c('tfoot',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
             handler: _vm.onLastRowReached,
             options: {
               threshold: [0.5],
             }
           }),expression:"{\n             handler: onLastRowReached,\n             options: {\n               threshold: [0.5],\n             }\n           }"}]},[_c('tr',[_c('td',{attrs:{"colspan":"4","align":"center"}},[_c(VIcon,{staticClass:"spin"},[_vm._v(" $refresh ")])],1)])]):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }