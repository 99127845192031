

























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AssignmentCommentType,
  IApiAssignmentComment,
} from '@/store/modules/assignments/contracts/assignment-comment.api-contract';
import dayjs from 'dayjs';
import { userModule } from '@/store/modules/user/user.module';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';

@Component({})
export default class CommentBubble extends Vue {
  @Prop()
  public readonly comment!: IApiAssignmentComment;

  public mounted(): void {
    if (
      assignmentModule.assignment?.comments &&
      assignmentModule.assignment?.comments?.findIndex((c) => c.id === this.comment.id) ===
        assignmentModule.assignment?.comments?.length - 1
    ) {
      this.$el.scrollIntoView({behavior: 'smooth'});
    }
  }

  public get formattedCommentDate(): string {
    if (dayjs(this.comment.commentDate).isBefore(dayjs('2022-03-15'))) {
      return '';
    }
    return `- ${dayjs(this.comment.commentDate).format('L')}`;
  }

  public get checkCommentType(): boolean {
    return this.comment.type === AssignmentCommentType.comment;
  }

  public get commentBubbleClass(): string {
    if (this.comment.type === AssignmentCommentType.edit) {
      return 'comment-bubble comment-bubble-edit';
    }
    if (this.comment.commentedBy.id === userModule.person?.id) {
      return 'comment-bubble comment-bubble--primary w-100';
    }
    return 'comment-bubble comment-bubble--secondary w-100';
  }
}
