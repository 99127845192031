import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 d-flex flex-column overflow-y-auto"},[_c('itaa-text-input',{staticClass:"mt-3",attrs:{"value":_vm.disciplineFilter,"label":_vm.$t('actions.search'),"required":false},on:{"input":_vm.updateDisciplineFilter}}),_c(VList,{staticClass:"mt-n7 flex-grow-1 overflow-y-auto",attrs:{"color":"transparent"}},[_c(VListItemGroup,{attrs:{"value":_vm.disciplineSelection,"multiple":""},on:{"change":_vm.disciplineSelected}},_vm._l((_vm.disciplines),function(discipline){return _c(VListItem,{key:discipline.id,attrs:{"value":discipline.id,"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_vm._t("left-action",null,{"active":active}),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(discipline.label)+" ")])],1)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }