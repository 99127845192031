
















import { Component, VModel, Vue } from 'vue-property-decorator';
import DisciplineList from '@/components/form-components/discipline-selection-input/DisciplineList.vue';

@Component({
  components: { DisciplineList },
})
export default class DisciplineSelectionInput extends Vue {
  @VModel()
  disciplineSelection!: string[];

  public disciplineSelected(val: string[]): void {
   this.disciplineSelection = val;
  }
}
