






import { Component, VModel, Vue } from 'vue-property-decorator';

@Component({})
export default class TimeInput extends Vue {
  @VModel()
  public readonly time!: string;
}
