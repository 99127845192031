import { IPersonBase } from '../../user/contracts/person.contract';

export interface IApiAssignmentComment {
  id: string;
  commentedBy: IPersonBase;
  commentDate: Date;
  comment: string;
  type: AssignmentCommentType;
}

export interface IApiAssignmentCommentData {
  comment: string;
  assignmentId: string;
}

export enum AssignmentCommentType {
  comment = 'comment',
  edit = 'edit',
}
