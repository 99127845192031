















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiAssignment } from '@/store/modules/assignments/contracts/assignment.api-contract';
import { userModule } from '@/store/modules/user/user.module';
import { AppRole } from '@/store/modules/user/contracts/role.enum';
import { DialogType } from '@itaa/ui-components/dist/types/dialog-type';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import { notificationService } from '@/services/notification.service';

@Component({})
export default class AssignmentDetails extends Vue {
  @Prop()
  public readonly assignment!: IApiAssignment;

  public isDialogOpen = false;
  public modalType: DialogType = 'ConfirmationModal';

  public get showContextMenu(): boolean {
    return userModule.role === AppRole.Intern;
  }

  public async deleteAssignment(): Promise<void> {
    try {
      await assignmentModule.deleteAssignment(this.assignment.id);
      notificationService.onSuccess(this.$t('assignment.delete.successMessage').toString());
    } catch (e) {
      notificationService.showGenericError();
    }
  }
}
