
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiAssignmentComment } from '@/store/modules/assignments/contracts/assignment-comment.api-contract';
import CommentBubble from './CommentBubble.vue';
import { assignmentModule } from '@/store/modules/assignments/assignment.module';
import TalkingMan from '@/assets/svg/talking-man.svg';
import { ValidationObserver } from 'vee-validate';
import { notificationService } from '@/services/notification.service';


@Component({
  components: {
    CommentBubble,
    TalkingMan,
    ValidationObserver
  },
})
export default class AssignmentComments extends Vue {
  @Prop()
  public readonly comments!: IApiAssignmentComment[];
  
  public commentModel = '';
  public sending = false;
  
  public async createComment(): Promise<void> {
    if (assignmentModule.assignment) {
      try {
        this.sending = true;
        await assignmentModule.addComment({comment: this.commentModel, assignmentId: assignmentModule.assignment.id });
      } catch (e) {
        notificationService.showGenericError();
      } finally {
        this.commentModel = '';
        this.sending = false;
      }
    }
  }
}
